// import Canuelas from './Canuelas';
interface WrapperDriveProps {
  docu: string
}
export default function WrapperDrive(props: WrapperDriveProps) {
  return (<>
    <iframe
      src={props.docu}
      width="100%"
      height="600"
      >
    </iframe>
  </>);
}
