import React, { FormEventHandler, useState } from 'react';

interface Product {
  _id: string,
  name: string,
}
interface ConsumeProps {
  products: Product[],
  onConsumeStock: Function,
}
const ConsumeForm: React.FC<ConsumeProps> = ({ products, onConsumeStock }) => {
  const [productId, setProductId] = useState('');
  const [quantity, setQuantity] = useState('');

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    onConsumeStock(productId, parseInt(quantity));
    setQuantity('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Consumir Stock</h2>
      <select value={productId} onChange={(e) => setProductId(e.target.value)} required>
        <option value="">Selecciona un producto</option>
        {products.map((product) => (
          <option key={product._id} value={product._id}>
            {product.name}
          </option>
        ))}
      </select>
      <input
        type="number"
        placeholder="Cantidad a consumir"
        value={quantity}
        onChange={(e) => setQuantity(e.target.value)}
        required
      />
      <button type="submit">Consumir</button>
    </form>
  );
};

export default ConsumeForm;
