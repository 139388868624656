import React, { useState } from 'react';
import { createProduct } from '../api';

interface ProductProps {
  onProductCreated: Function
}

const ProductForm:React.FC<ProductProps> = ({ onProductCreated }) => {
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const newProduct = await createProduct({ name, price });
    onProductCreated(newProduct);
    setName('');
    setPrice('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Crear Producto/Servicio</h2>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Nombre del producto"
        required
      />
      <input
        type="number"
        value={price}
        onChange={(e) => setPrice(e.target.value)}
        placeholder="Precio"
        required
      />
      <button type="submit">Crear</button>
    </form>
  );
};

export default ProductForm;
