// src/auth/PrivateRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

interface SecureRouteProps {
  component: React.ElementType,
  child?: any,
  authenticated?: boolean,
}

const SecureRoute: React.FC<SecureRouteProps>  = ({ component: Component, authenticated, child, ...rest }) => {
  // Add your authentication logic here
  const {isAuthenticated } = useAuth();
  return isAuthenticated ? <Component child {...rest} /> : <Navigate to="/signin" replace />;
};
export default SecureRoute;
