import React from 'react';
import { addStock, consumeStock } from '../api';

interface ProductListProps {
  products: any[]
  handleStockChange: Function
}
const ProductList: React.FC<ProductListProps> = (props) => {
  const { products } = props;

  const handleAddStock = async (id: string) => {
    await addStock(id, { stock: 10 }); // Aumenta 10 unidades
    props.handleStockChange();
  };

  const handleConsumeStock = async (id: string) => {
    await consumeStock(id, { quantity: 5 }); // Consume 5 unidades
    props.handleStockChange();
  };

  return (
    <div>
      <h2>Lista de Productos</h2>
      <ul>
        {products.map((product) => (
          <li key={product._id}>
            {product.name} - Precio: ${product.price} - Stock: {product.stock}
            <button onClick={() => handleAddStock(product._id)}>Cargar Stock</button>
            <button onClick={() => handleConsumeStock(product._id)}>Consumir Stock</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProductList;
