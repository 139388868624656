// src/auth/AuthContext.js

import React, { createContext, useState, useContext, MouseEventHandler } from 'react';

class AuthContextClass {
    public isAuthenticated: boolean = false;
    public login: Function = () => {};
    public logout: MouseEventHandler<HTMLButtonElement> = () => {};
}

// Create a Context for authentication
const AuthContext  = createContext(new AuthContextClass());

// Create a provider component
export const AuthProvider: React.FC<any> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem("access_token"));

    const login = () => setIsAuthenticated(true);
    const logout = () => {
        setIsAuthenticated(false);
        localStorage.removeItem('access_token');
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook to use the AuthContext
export const useAuth = () => useContext(AuthContext);
