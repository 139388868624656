import React, { useState } from 'react';

interface StockFormProps {
  products: any[]
  onAddStock: Function
}
const StockForm: React.FC<StockFormProps> = ({ products, onAddStock }) => {
  const [productId, setProductId] = useState('');
  const [stock, setStock] = useState('');

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onAddStock(productId, parseInt(stock));
    setStock('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Cargar Stock</h2>
      <select value={productId} onChange={(e) => setProductId(e.target.value)} required>
        <option value="">Selecciona un producto</option>
        {products.map((product) => (
          <option key={product._id} value={product._id}>
            {product.name}
          </option>
        ))}
      </select>
      <input
        type="number"
        placeholder="Cantidad de stock"
        value={stock}
        onChange={(e) => setStock(e.target.value)}
        required
      />
      <button type="submit">Cargar Stock</button>
    </form>
  );
};

export default StockForm;
