import * as React from 'react';
import {
  Button,
} from '@mui/material';
import { Link } from 'react-router-dom';

const docu = "https://docs.google.com/spreadsheets/d/1O2uJbka3RIX4B1eivhJx-7wn1Hun3746aUO6aTD4hQ4/pubhtml?widget=true&amp;headers=false"
export default function Canuelas() {

  return (<React.Fragment>
    {<>
        <Button component={Link} to="/">
          Proyecto Canuelas
        </Button>
        <Button component={Link} to="/products">
          Productos
        </Button>
        <Button component={Link} to="/users">
          Users
        </Button>
        <Button component={Link} to="/dashboard">
          Dashboard
        </Button>
        <iframe
          src={docu}
          width="100%"
          height="600"
        >
        </iframe>
      </>}
    </React.Fragment>
  );
}
