export default {
    "sidebar.home": "Home",
    "sidebar.dashboard": "Dashboard",
    "sidebar.products": "Products",
    "sidebar.products.list": "List",
    "sidebar.products.add": "New",
    "sidebar.products.stock": "Stock",
    "sidebar.messages": "Messages",
    "sidebar.users": "Users",
    "sidebar.support": "Support",
    "sidebar.settings": "Settings"
}