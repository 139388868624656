import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';

import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import UserTable from '../components/UserTable';
import ProductForm from '../components/ProductForm';

import { useNavigate } from 'react-router-dom';

export default function JoyProductForm() {
  const [products, setProducts] = useState([]);  
  const navigate = useNavigate();
  
  useEffect(() => {
    fetchProducts();
    return () => {}
  }, []);

  const fetchProducts = useCallback(async () => {
    try {
      const response = await axios.get('http://localhost:3000/api/products', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
      });
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  },[localStorage.getItem('access_token')]);

  const handleProductCreated = (newProduct: never) => {
    setProducts([...products, newProduct]);
  };

  const handleAddStock = useCallback(async (productId: string, stock: string) => {
    try {
      await axios.post(`http://localhost:3000/api/products/${productId}/stock`, { stock }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
      });
      fetchProducts();
    } catch (error) {
      console.error('Error adding stock:', error);
    }
  }, [localStorage.getItem('access_token')]);

  const handleConsumeStock = useCallback(async (productId: string, quantity: string) => {
    try {
      await axios.post(`http://localhost:3000/api/products/${productId}/consume`, { quantity }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
      });
      fetchProducts();
    } catch (error) {
      console.error('Error consuming stock:', error);
    }
  }, [localStorage.getItem('access_token')]);

  const handleStockChange = useCallback(async (productId: string, quantity: string) => {
    try {
      fetchProducts();
    } catch (error) {
      console.error('Error updating stock:', error);
    }
  }, [localStorage.getItem('access_token')]);
  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
        <Header />
        <Sidebar />
        <Box
          component="main"
          className="MainContent"
          sx={{
            px: { xs: 2, md: 6 },
            pt: {
              xs: 'calc(12px + var(--Header-height))',
              sm: 'calc(12px + var(--Header-height))',
              md: 3,
            },
            pb: { xs: 2, sm: 2, md: 3 },
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            height: '100dvh',
            gap: 1,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Breadcrumbs
              size="sm"
              aria-label="breadcrumbs"
              separator={<ChevronRightRoundedIcon fontSize="small" />}
              sx={{ pl: 0 }}
            >
              <Link
                underline="none"
                color="neutral"
                href="#some-link"
                aria-label="Home"
              >
                <HomeRoundedIcon />
              </Link>
              <Link
                underline="hover"
                color="neutral"
                href="#some-link"
                sx={{ fontSize: 12, fontWeight: 500 }}
              >
                Dashboard
              </Link>
              <Typography color="primary" sx={{ fontWeight: 500, fontSize: 12 }}>
                Products
              </Typography>
            </Breadcrumbs>
          </Box>
          <Box
            sx={{
              display: 'flex',
              mb: 1,
              gap: 1,
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'start', sm: 'center' },
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <Typography level="h2" component="h1">
              Products
            </Typography>
            <Button
              color="primary"
              startDecorator={<DownloadRoundedIcon />}
              size="sm"
              onClick={() => navigate("/products")}
            >
              List
            </Button>
          </Box>
          <ProductForm onProductCreated={handleProductCreated} />
          </Box>
      </Box>
    </CssVarsProvider>
  );
}
