export default {
    "sidebar.home": "Inicio",
    "sidebar.dashboard": "Panel",
    "sidebar.products": "Productos",
    "sidebar.products.all": "Listado",
    "sidebar.products.add": "Nuevo",
    "sidebar.products.stock": "Inventario",
    "sidebar.messages": "Mensajes",
    "sidebar.users": "Usuarios",
    "sidebar.support": "Mesa de ayuda",
    "sidebar.settings": "Configuraciones"
}